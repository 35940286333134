import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { getDefaultFinancialYear } from "../../../../library/get-page-settings"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
	IconPlaceholder,
} from "../../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../../components/basic-elements"
import ReleaseForceArchiveDialog from "./ReleaseForceArchiveDialog"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"
import DeleteProjectDialog from "../../../../components/combined-elements/delete-project-dialog/DeleteProjectDialog"
import FaPreProcessDialog from "./dialogs/FaPreProcessDialog"
import FaZipRegenerationDialog from "./dialogs/FaZipRegenerationDialog"

export default observer(function AMForceArchivedController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.am_force_archived
	const projStatus = ProjectStatus.forceArchived
	const actionName = "getArchivedProjects"
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgStore.archiveMgmt.needRefreshForForceArchive
	const i18nSheet = OrgI18n.archMgmt

	const loadingStatus = orgStore.archiveMgmt.getActionStatus(actionName)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const clickPointExist = orgStore.archiveMgmt.clickPoint.mouseX !== null

	console.log(clickPointExist, menuAccess, permission, "permission")

	const selectedProj = orgStore.archiveMgmt.selectedProj
	const projNumId = idToNumber(selectedProj, IdType.project)

	const menuOptions = [
		{
			label: "Open",
			value: "open",
			available: permission.read,
			isLink: true,
			linkTo: `/project/checkin/${projNumId}`,
			icon: <IconPlaceholder />,
		},
		{
			label: "Project Information", // `${i18n.twProjectDetails}`,
			value: "ctx-proj_info", // "ctx-details",
			available: permission.read,
			// disabled: false,
			clickEvent: () =>
				orgStore.archiveMgmt.setProjectDetailsDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Force-Archived Pre-Process PDFs",
			value: "force-archived-pre-process-pdfs",
			available: permission.read,
			clickEvent: () => orgStore.archiveMgmt.setPreProcessDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Force-Archived Zip",
			value: "force-archived-zip",
			available: permission.read,
			clickEvent: () =>
				orgStore.archiveMgmt.setArchiveZipRegenerationDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Release Force-Archived",
			value: "release-force-archive",
			available: permission.read,
			clickEvent: () =>
				orgStore.archiveMgmt.setReleaseForceArchiveDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twDelete}`,
			value: "ctx-proj_delete", // "ctx-delete",
			available: permission.delete,
			disabled: false,
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
			clickEvent: () =>
				orgStore.archiveMgmt.setRemoveArchivedProjectDialogOpen(true),
		},
	]

	useEffect(() => {
		const defaultYear = getDefaultFinancialYear(menuId)
		if (menuAccess && needRefresh) {
			if (menuAccess && needRefresh) {
				orgStore.archiveMgmt.getArchivedProjects({
					year: defaultYear,
					usage: 3,
				})
			}
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div />}
					hasToolbar={false}
					fullWidth
					fixedHeight
					isLoading={loadingStatus === "LOADING"}
				>
					<StyledAMForceArchivedProjects>
						{loadingStatus === "SUCCESS" && (
							<SharedProjectTableV8
								partialStore={orgStore.archiveMgmt}
								i18n={i18n}
								// common part 2
								projScreenType={ProjScreenType.amForceArchived}
								menuId={DLOrgSubMenus.am_force_archived}
								projectStatus={projStatus}
								permission={permission}
							/>
						)}
					</StyledAMForceArchivedProjects>
					{clickPointExist && (
						<DLContextMenu
							eleId="am-force-archive-ctx-menus"
							clickPoint={{
								mouseX: orgStore.archiveMgmt.clickPoint.mouseX,
								mouseY: orgStore.archiveMgmt.clickPoint.mouseY,
							}}
							handleClose={() =>
								orgStore.archiveMgmt.setClickPoint({
									mouseX: null,
									mouseY: null,
								})
							}
							menuOptions={menuOptions}
							hasDivider={[1, 3, 4]}
						/>
					)}
					{orgStore.archiveMgmt.projectDetailsDialogOpen && (
						<ProjectDetailsDialog
							partialStore={orgStore.archiveMgmt}
						/>
					)}
					{orgStore.archiveMgmt.preProcessDialog && (
						<FaPreProcessDialog
							partialStore={orgStore.archiveMgmt}
						/>
					)}
					{orgStore.archiveMgmt.archiveZipRegenerationDialog && (
						<FaZipRegenerationDialog
							partialStore={orgStore.archiveMgmt}
							from={"archiveMgmt"}
						/>
					)}
					{orgStore.archiveMgmt.releaseForceArchiveDialog && (
						<ReleaseForceArchiveDialog
							i18n={i18n}
							isOpen={
								orgStore.archiveMgmt.releaseForceArchiveDialog
							}
							setIsOpen={
								orgStore.archiveMgmt
									.setReleaseForceArchiveDialog
							}
							partialStore={orgStore.archiveMgmt}
							dnt={store.global.getDntFormat}
						/>
					)}
					{orgStore.archiveMgmt.removeArchivedProjectDialogOpen && (
						<DeleteProjectDialog
							i18n={i18n}
							isOpen={
								orgStore.archiveMgmt
									.removeArchivedProjectDialogOpen
							}
							setIsOpen={
								orgStore.archiveMgmt
									.setRemoveArchivedProjectDialogOpen
							}
							deleteAction={() =>
								orgStore.archiveMgmt.removeAmArchivedProject(
									selectedProj,
									orgStore.archiveMgmt.selectedCabinetId,
									true
								)
							}
							projectInfo={orgStore.archiveMgmt.viewSelectedProjInfo(
								selectedProj
							)}
						/>
					)}
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={orgStore.archiveMgmt}
					/>
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledAMForceArchivedProjects = styled.div`
	padding: 0 1rem;
`
